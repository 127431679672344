import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope, faGlobeAmericas, faCheckDouble,
  faHandshake, faDatabase, faUserShield, faGavel, faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button, Form, FormGroup, Label, Input, FormText,
} from 'reactstrap';
import Blog01 from '../images/blog/blog.jpg';
import Layout from '../layouts/index';
import BlogArticle from '../components/Blog';
import Clients from '../components/Clients';
import ProductCLM1 from '../images/product-clm-1.png';
import ProductCLM2 from '../images/product-clm-2.png';
import DemoForm from '../components/DemoForm';
import Dashboard from '../images/500-startups/dashboard-starter.svg';
import Dashboard2 from '../images/500-startups/dashboard-starter-2.svg';
import Logo500 from '../images/500-startups/logo-500.png';
import SemiCircle from '../images/500-startups/semi-circle.svg';
import MujerStarter from '../images/mujer-starter.jpg';
import TratoClmHero from '../images/trato-clm-hero.png';
import ctaImage from '../images/cta-image.png';


function TratoClm({ pageContext: { locale }, location }) {
    const { t } = useTranslation()
  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing bg-grey" inverted border location={location}>
      <section className="bg-grey">
        <div className="container mt-3 py-5">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <div className="align-self-center p-3">
                <h1 className="mb-2">{t('tratoClm.heroTitle1')} <span className="font-weight-bold underline-primary">{t('tratoClm.heroTitle2')}</span></h1>
                <p className="lead text-muted mb-3">{t('footerSection.subheading')}</p>
                <DemoForm type="trato-clm" />
              </div>
            </div>
            <div className="col-lg-6">
              <img src={TratoClmHero} className="img-fluid" alt="CLM" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container text-center">
          <div className="w-75 mx-auto">
            <h2 className="font-weight-bold">{t('tratoClm.heading2')}</h2>
            <p className="lead text-muted">{t('tratoClm.paragraph2')}</p>
          </div>
          <Clients />
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 d-flex bg-light py-2">
              <div className="align-self-center">
                <h2 className="font-weight-bold mb-4">{t('tratoClm.heading3')}</h2>
                <ul className="list-unstyled">
                  <li className="d-flex mb-5">
                    <div className="align-self-center p-2 badge-primary-50 circle mr-2">
                      <div className="d-flex"><FontAwesomeIcon icon={faFileSignature} className="lead text-primary align-baseline icon-sm align-self-center" />
                      </div>
                    </div>
                    <div className="align-self-center">
                      <h5 className="font-weight-bold">
                        {t('tratoClm.featureTitle1')}
                      </h5>
                      <span className="text-muted">{t('tratoClm.featureText1')}</span>
                    </div>
                  </li>
                  <li className="d-flex mb-5">
                    <div className="align-self-center p-2 badge-primary-50 circle mr-2">
                      <div className="d-flex"><FontAwesomeIcon icon={faGavel} className="lead text-primary align-baseline icon-sm align-self-center" />
                      </div>
                    </div>
                    <div className="align-self-center">
                      <h5 className="font-weight-bold">{t('tratoClm.featureTitle2')}</h5>
                      <span className="text-muted">{t('tratoClm.featureText2')}</span>
                    </div>
                  </li>
                  <li className="d-flex"> <div className="align-self-center p-2 badge-primary-50 circle mr-2"><div className="d-flex"><FontAwesomeIcon icon={faGlobeAmericas} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div><div className="align-self-center"><h5 className="font-weight-bold">{t('tratoClm.featureTitle3')}</h5><span className="text-muted">{t('tratoClm.featureText3')}</span></div></li>
                </ul>
              </div>

            </div>

            <div className="col-lg-6 d-flex bg-light py-2">
              <img src={ProductCLM1} className="img-fluid align-self-center" alt=""/>
            </div>

          </div>
        </div>
      </section>

      <section className="bg-grey">
        <div className="container">
          <div className="row">

            <div className="col-lg-6 bg-grey d-flex py-2">
              <img src={ProductCLM2} className="img-fluid align-self-center" alt=""/>
            </div>

            <div className="col-lg-6 bg-grey d-flex py-2">
              <div className="align-self-center">
                <ul className="list-unstyled">
                  <li className="d-flex mb-5"><div className="align-self-center p-2 badge-primary-50 circle mr-2"><div className="d-flex"><FontAwesomeIcon icon={faCheckDouble} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div><div className="align-self-center"><h5 className="font-weight-bold">{t('tratoClm.featureTitle4')}</h5><span className="text-muted">{t('tratoClm.featureText4')}</span></div></li>
                  <li className="d-flex mb-5"><div className="align-self-center p-2 badge-primary-50 circle mr-2"><div className="d-flex"><FontAwesomeIcon icon={faHandshake} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div><div className="align-self-center"><h5 className="font-weight-bold">{t('tratoClm.featureTitle5')}</h5><span className="text-muted">{t('tratoClm.featureText5')}</span></div></li>
                  <li className="d-flex mb-5"><div className="align-self-center p-2 badge-primary-50 circle mr-2"><div className="d-flex"><FontAwesomeIcon icon={faDatabase} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div><div className="align-self-center"><h5 className="font-weight-bold">{t('tratoClm.featureTitle6')}</h5><span className="text-muted">{t('tratoClm.featureText6')}</span></div></li>
                  <li className="d-flex"><div className="align-self-center p-2 badge-primary-50 circle mr-2"><div className="d-flex"><FontAwesomeIcon icon={faUserShield} className="lead text-primary align-baseline icon-sm align-self-center" /></div></div><div className="align-self-center"><h5 className="font-weight-bold">{t('tratoClm.featureTitle7')}</h5><span className="text-muted">{t('tratoClm.featureText7')}</span></div></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-grey border-bottom">
        <div className="container">
          <div className="bg-light mx-auto rounded shadow-sm">
            <div className="row">
              <div className="col-lg-8 d-flex py-2 px-4">
                <div className="align-self-center">
                  <h3 className="font-weight-bold">{t('tratoClm.CtaTitle')}</h3>
                  <p className="lead text-muted mb-3">{t('tratoClm.CtaText')}</p>
                  <DemoForm type="trato-clm"/>
                </div>
              </div>
              <div className="col-lg-4 d-none d-lg-block">
                <img src={ctaImage} className="img-fluid rounded-right" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section className="py-5 bg-light">
          <div className="container">
              <div className="bg-primary cta-container rounded">
                  <div className="row">
                      <div className="col-lg-6 col-sm-12 py-3 px-5">
                      <img src={Logo500} className="img-fluid logo-500 mb-2" />
                        <p className="text-light mb-0">
                        Debido a que somos una empresa que participó en el programa
                        500 Startups y como agradecimiento al vasto conocimiento que adquirimos
                        en el programa de aceleración, por haber sido considerado
                        en esta gran institución,
                        podrás usar TRATO STARTER de manera gratuita
                        con firmas digitales blindaje 1 y además te
                        incluimos sin costo una plantilla
                        de Nota Convertible para levantar inversión.
                        </p>
                      </div>
                      <div className="col-lg-6 col-sm-12 position-relative">
                          <img src={Dashboard2} className="img-fluid dashboard-cta" />
                      </div>
                  </div>
              </div>
          </div>
      </section>

            <section className="bg-light py-5">
                <div className="container">
                    <div className="text-center w-75 mx-auto mb-5">
                    <p className="lead text-muted font-weight-bold">
                        Además, si tienes cualquier duda sobre la transformación 100% digital
                        de tu proceso legal, podrás tener 1 hora de consultoría sin COSTO con
                        nuestro equipo de Legaltech.
                    </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <p className="text-muted">
                                TRATO es una solución en la nube donde podrás crear, gestionar,
                                negociar y firmar tus contratos digitalmente
                                utilizando 3 niveles de blindaje,
                                ya sea asegurando tu firma con una videollamada
                                o con el blindaje que asegura una
                                completa trazabilidad a través del uso de blockchain.
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-muted">
                                Actualmente, TRATO es utilizada po
                                más de 5,250 usuarios (startups, bancos y corporativos)
                                incluyendo Samsung, Estafeta, Rappi, Cabify, Homie,
                                entre muchos otros.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
    </Layout>


  );
}

export default TratoClm;
